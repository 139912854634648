import api from '../utils/result'

/**
 * 此文件为接口文件，是所有页面接口所在的文件
 */

/**
 * 主页接口
 * @param lang {number} 要传递的语言参数  (1：中文，  2：英文)
 * @param index {number} 当前页面的参数  (1:首页， 2：简介，3：业务，4：员工，5：投资者，6：媒体)
 * @returns {Promise<axios.AxiosResponse<any>>}
 */

function getPage (lang, index) {
    return api({
        url: '/pageInfo',
        method: 'get',
        params: {
            lang: lang * 1,
            index: index
        }
    })
}

/**
 * 首页页面的数据
 * @param lang 语言的状态
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
function getHome (lang) {
    return api({
        url: '/home',
        method: 'get',
        params: {
            lang: lang * 1
        }
    })
}

/**
 * 业务页面的数据
 * @param lang 语言的状态
 * @returns {Promise<axios.AxiosResponse<any>>}
 */


function getSmallImage (lang) {
    return api({
        url: '/business',
        method: 'get',
        params: {
            lang: lang * 1
        }
    })
}

/**
 * 简介页面的数据
 * @param lang 语言的状态
 * @returns {Promise<axios.AxiosResponse<any>>}
 */


function getIntroduce (lang) {
    return api({
        url: '/introduce',
        method: 'get',
        params: {
            lang: lang * 1
        }
    })
}

/**
 * 媒体页面的数据
 * @param lang 语言的状态
 * @returns {Promise<axios.AxiosResponse<any>>}
 */


function getMedia (lang) {
    return api({
        url: '/media',
        method: 'get',
        params: {
            lang: lang * 1
        }
    })
}

/**
 * 员工界面接口
 * @param lang 语言的状态
 * @returns {Promise<axios.AxiosResponse<any>>}
 */

function getStaff (lang) {
    return api({
        url: '/staff',
        method: 'get',
        params: {
            lang: lang * 1
        }
    })
}

/**
 * 媒体资料库展开页面
 * @param lang 语言的状态
 * @param index 对应板块的索引
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
function getList (lang, index) {
    return api({
        url: '/source/source_list',
        method: 'get',
        params: {
            lang: lang * 1,
            index: index,
          
    
        }
    })
}

/**
 * 媒体页面的企业动态展开
 * @param lang 语言的状态
 * @param index 对应板块的索引 企业:51 赛事:52
 * @param num 页数
 * @param size 个数
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
function getNewList (lang, index,num,size) {
    return api({
        url: '/news/source_list',
        method: 'get',
        params: {
            lang: lang * 1,
            index: index,
            pageNum:num,
            pageSize:size
        }
    })
}

/**
 * 新闻的详情页
 * @param id 对应新闻的id值
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
function getNewContent (id) {
    return api({
        url: '/news/check_news',
        method: 'get',
        params: {
            Id: id
        }
    })
}

/**
 * 底部接口
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
function getFooter () {
    return api({
        url:'/thrid/list',
        method:'get'
    })
}

export default {
    getPage,
    getHome,
    getSmallImage,
    getIntroduce,
    getMedia,
    getStaff,
    getList,
    getNewList,
    getNewContent,
    getFooter
}
