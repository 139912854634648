const media = {
    //中文翻译
    ZH: {
        more: '查看详情',
        all: '查看全部',
        libraryTitle: '媒体资料库',
        library: [
            '视频资料',
            '企业图片',
            '活动图集'
        ],
        address:'公司地址',
        connection:'联系我们',
        cancel:'取消',
        confirm:'确认'
    },
    //英文翻译
    EN: {
      
        more: 'Learn More',
        all: 'View All',
        library: [
            'Video',
            'Image',
            'Events'
        ],
        libraryTitle: 'MEDIA LIBRARY',
        address:'Address',
        connection: 'Contact Details',
        cancel:'Cancel',
        confirm:'Confirm'
        
    }
}
export default media
