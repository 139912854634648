const index = {
    //中文翻译
    ZH: {
        news: '公司动态',
        event: '赛事动态',
        about: '公司简介',
        culture: '企业文化',
        business: '企业业务',
        view: '查看全部动态',
        more:'了解详情',
     
        
    },
    //英文翻译
    EN: {
        news: 'news',
        event: 'Events',
        about: 'About Us',
        culture: 'Corporate Culture',
        business: 'Business',
        view: 'View All Dynamics',
        more:'Learn More',
    }
}
export default index;
