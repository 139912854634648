/**
 * 时间戳工具 负责处理一些页面中的时间
 * @param time
 * @returns {{month: (number|string), year: number, day: (number|string)}}
 */

function getTime (time) {
    let date = new Date(time)
    // console.log("date======",date)
    // console.log("Month======",date.getMonth())
    // console.log("Month======",date.getDate())

    //date.getMonth() > 9? date.getMonth() : '' + date.getMonth() + 1
    let month1 = date.getMonth() + 1
    return {
        year: date.getFullYear(),
        month: month1,
        day: date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
    }
}

export default getTime