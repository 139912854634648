<template>
    <div class="Top" :class="{active:flag}" @click="scrollTop">

    </div>
</template>

<script>
export default {
    name: "Top",
    components: {},
    data () {
        return {
            flag: false
        }
    },
    methods: {
        top () {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            this.flag = scrollTop > 300? true : false;
        },
        scrollTop(){
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    },
    mounted () {
        window.addEventListener('scroll', this.top)

    },
    destroyed () {
        window.removeEventListener('scroll', this.top)
    }

}
</script>


<style scoped lang="scss">

/*-- -------Top---------- --*/
.Top {
    width: 4.3125rem;
    height: 4.3125rem;
    border-radius: 50%;
    border: 0.125rem solid #ED111C;
    background: #fff url("../assets/top.png") no-repeat center center;
    background-size: 1.6875rem 1.0625rem;
    cursor: pointer;
    z-index: 10000;
}

.active {
    position: fixed;
    right: 2.5rem;
    bottom: 8.75rem;
}
</style>
