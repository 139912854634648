//导航栏中英文
const navList = {
    //中文翻译
    ZH: [
        {
            id: 1,
            name: '简介',
            menuList: [
                '公司简介',
                '使命愿景',
                '业务架构',
                '管理团队',
                '办公地点'
            ]
        },
        {
            id: 2,
            name: '业务',
            menuList: [
                '赛事组织及内容制作',
                '电竞商业化',
                '社群运营',
                '电竞商业综合体',
                '虚拟制作',
                '电竞教育'
            
            ]
        },
        {
            id: 3,
            name: '员工',
            menuList: []
        },
        {
            id: 4,
            name: '投资者',
            hide: true,
            menuList: []
        },
        {
            id: 5,
            name: '媒体',
            menuList: [
                '企业动态',
                '赛事动态',
                '媒体资料库',
                '联系我们'
            ]
        }],
    //英文翻译
    EN: [
        {
            id: 1,
            name: 'VSPO',
            menuList: [
                'About Us',
                'Our Story',
                'Business Architecture',
                'Management Team',
                'Office Location'
            ]
        },
        {
            id: 2,
            name: 'Business',
            menuList: [
                'Event Organization and Content Production',
                'Commercialization of E-sports',
                'Community Operation',
                'E-sports Commercial Complex',
                'Virtual Production',
                'E-sports Education'
            
            ]
        },
        {
            id: 3,
            name: 'Staff',
            menuList: [
                // 'VSPO Academy',
                // 'Talent Development',
                // 'Working Environment',
                // 'Staff Activity'
            ]
        },
        {
            id: 4,
            name: 'Investor',
            hide: true,
            menuList: []
        },
        {
            id: 5,
            name: 'Media',
            menuList: [
                'Newsroom',
                'Events',
                'Media Library',
                'Contact Us'
            ]
        }
    ]
}
export default navList
