import footer from './data/footer'
import navList from './data/nav'
import media from './data/media'
//import introduce from './data/introduce'
import business from './data/business'
import index from './data/Index'
import staff from './data/staff'
const zh = {
    language: {
        name: 'English'
    },
    Nav: navList.ZH,
    Footer: footer.ZH,
    //Introduce:introduce.ZH,
    Media:media.ZH,
    Business:business.ZH,
    Index:index.ZH,
    Staff:staff.ZH,
}
export default zh
