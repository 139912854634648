import axios from 'axios'

/**
 * 封装axios请求
 * @type {axios.AxiosInstance}
 */


const api = axios.create({
    baseURL:process.env.VUE_APP_API_URL,
    timeout: 3000
})

// 添加请求拦截器
api.interceptors.request.use(
        (config) => {
            return config;
        }, (error) => {
            return error
        });

// 添加响应拦截器
api.interceptors.response.use(
        (response) => {
            return response;
        }, (error) => {
            return error
        });
export default api


