<template>
    <div id="app">
        <keep-alive>
            <router-view />s
        </keep-alive>

    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    },
    methods: {
        changeSize () {
            let baseSize = 13.8;
            let desSize = document.documentElement.clientWidth <= 768 ? 750 : 1920;
            //if(desSize===750){
            //    baseSize=100
            //}
            console.log(desSize)
            let scale = document.documentElement.clientWidth / desSize;
            document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px';
            if (document.documentElement.clientWidth >= 1920) {
                document.documentElement.style.fontSize = '16px';
            }
            this.$store.commit('changeScreen', desSize)
        },

    },
    created () {
        this.changeSize()
    },
    mounted () {

    }

}

</script>


<style lang="scss">
@import 'publiccss/index.css';

#app {
    //max-width: 120rem;
    margin: 0 auto;
}

</style>
