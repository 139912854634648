
import footer from './data/footer'
import navList from './data/nav'
import media from './data/media'
//import introduce from './data/introduce'
import business from './data/business'
import index from './data/Index'
import staff from './data/staff'
const en = {
    language: {
        name: '中文'
    },
    Nav: navList.EN,
    Footer: footer.EN,
    //Introduce:introduce.EN,
    Media: media.EN,
    Business:business.EN,
    Index:index.EN,
    Staff: staff.EN
}
export default en
