const staff = {
    //中文翻译
    ZH: {
        list: [
            {
                name: '校园招聘',
                src: ''
            },
            {
                name: '社会招聘',
                src: ''
            },
            {
                name: '国际招聘',
                src: ''
            }
        ]
        
    },
    //英文翻译
    EN: {
        list: [
            {
                name: 'Social<br/>(Recruitment)',
                src: ''
            },
            {
                name: 'Campus<br/>(Recruitment)',
                src: ''
            },
            {
                name: 'International<br/>(Recruitment)',
                src: ''
            }
        ]
        
    }
}
export default staff
