import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
    {
        path: '/home',
        name: 'Home',
        component: () => import('../views/Index/Index')
    },
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/introduce',
        name: 'Introduce',
        component: () => import('../views/Introduce/Introduce')
    },
    {
        path: '/business',
        name: 'Business',
        component: () => import('../views/Business/Business')
    },
    {
        path: '/staff',
        name: 'Staff',
        component: () => import('../views/Staff/Staff')
    },
    {
        path: '/media',
        name: 'Media',
        component: () => import('../views/Media/Media')
    },
    //动态
    {
        path: '/movement',
        name: 'Movement',
        component: () => import('../views/Movement/Movement')
    },
    //资料库
    {
        path: '/library',
        name: 'Library',
        component: () => import('../views/Spread/Library')
    },
    //内容
    {
        path: '/content',
        name: 'Content',
        component: () => import('../views/Content/Content')
    }
]

const router = new VueRouter({
    routes,
    //mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        //return {
        //    x: 0,
        //    y: 0
        //}
    }
})

export default router
