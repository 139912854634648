/**
 * 获取当前页面的中的title标题，和keyword以及description标签元素，并设置新值
 */

 function getMedia(){
    let head = document.querySelector('head')
    document.title = this.meta.Title
    if (document.querySelector('meta[name="keywords"]')&&document.querySelector('meta[name="description"]')){
        document.querySelector('meta[name="keywords"]').setAttribute('content', this.meta.Keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', this.meta.Descript)
        //console.log('有')
    }else {
        //console.log('五')
        let meta = document.createElement('meta');
        let metaKey = document.createElement('meta')
       
        meta.name = 'keywords'
        meta.content = this.meta.Keywords;
        metaKey.name = 'description'
        metaKey.content = this.meta.Descript
        head.appendChild(meta)
        head.appendChild(metaKey)
    }
}
export default getMedia
