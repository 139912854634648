const business = {
    //中文翻译
    ZH: {
        events: '主要承办赛事',
        customer: '主要合作客户',
        global: '全球赛事',
        tech: '技术',
        remote: '远程制作',
        brands: '服务品牌',
        celebrity: '公司艺人'
        
    },
    //英文翻译
    EN: {
        events: 'Major Events',
        customer: 'Major Customer',
        global: 'Global Events',
        tech: 'Technology',
        remote: 'Remote Production',
        brands: 'Brands',
        celebrity: 'Celebrity'
        
    }
}
export default business

