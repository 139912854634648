 const footer = {
    //中文翻译
    ZH: {
        join: '联系我们',
        href:[
            {
                name:'社会招聘',
                src:'#'
            },
            {
                name:'校园招聘',
                src:'#'
            },
            {
                name:'国际招聘',
                src:'#'
            },
        ],
        phone:'加入我们',
        joinList:[
            {
                name:'联系方式',
                src:'#'
            },
            {
                name:'公司地址',
                src:'#'
            },
        ],
        follow:'关注我们'
    },
    //英文翻译
    EN: {
        join: 'Join Us',
        href:[
            {
                name:'Social Recruitment',
                src:'#'
            },
            {
                name:'Campus Recruitment',
                src:'#'
            },
            {
                name:'International Recruitment',
                src:'#'
            },
        ],
        phone:'Contact Us',
        joinList:[
            {
                name:'Contact Details',
                src:'#'
            },
            {
                name:'Office Address',
                src:'#'
            },
        ],
        follow:'Follow us'
    },
}
 export default footer
