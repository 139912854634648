import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import { VuexPersistence } from 'vuex-persist'
// 配置持久化实例对象，（会话存储）
const vuexLocal = new VuexPersistence({
    storage: window.sessionStorage
});
export default new Vuex.Store({
    state: {
        navType: 0, //导航点击之后的索引（点击之后添加样式）
        menuIndex: -1,//二级导航栏点击的索引
        screenType: false,//判断是否为移动端还是pc端
        language: false,//中英文切换时，控制元素的显示和隐藏
        languageIndex:1,//语言的索引，1代表中文 2代表英文
        languageSet: false,
        lang:'zh',
        //baseUrl 为当前图片路径的前缀
        baseUrl:process.env.VUE_APP_API_URL,
    },
    mutations: {
        changeType (state, value) {
            state.navType = value
        },
        changeIndex (state, value) {
            state.menuIndex = value
        },
        changeScreen (state, value) {
            let result = value === 750 ? true : false
            state.screenType = result
            
        },
        changeLanguage(state,value){
            state.language = value[0]
            state.languageIndex=value[1]
            state.languageSet = true
        },
        changeLang(state,value){
            state.lang=value
        }
    },
    actions: {},
    modules: {},
    plugins: [
        vuexLocal.plugin
    ]
})
