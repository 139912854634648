import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Top from './components/Top'//回到顶部组件，并将其设置为全局组件

Vue.config.productionTip = false
//引入封装好的接口
import api from './api/api'
//引入封装好的方法
import getMedia from './utils/getMedia'
import getTime from './tools/getTime'
//中英文切换
import ZH from './components/language/zh'
import EN from './components/language/en'
import VueI18n from 'vue-i18n'

//导入外部字体样式
import '@/assets/font/font.css'

import VueQuillEditor from 'vue-quill-editor'
// require styles 导入富文本编辑器对应的样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor, /* { default global options } */)
Vue.use(VueI18n)
//中英文切换
const i18n = new VueI18n({
    locale: localStorage.getItem('languageSet') || 'zh',
    //从localStorage里获取用户中英文选择，没有则默认中文
    messages: {
        'zh': ZH,
        'en': EN
    }
})

//将常用的方法挂载到原型
Vue.prototype.$getMedia = getMedia
Vue.prototype.$getTime= getTime

//将封装好的api挂载到原型,可以直接进行调用
Vue.prototype.$http = api

Vue.component('top', Top)

new Vue({
    router,
    store,
    i18n,   //把 i18n 挂载到 vue 根实例上
    render: h => h(App)
}).$mount('#app')

//当路由刷新的时候，将页面滚动到页面的顶部或者当要跳转到特定的页面需要处理的放在这里
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0)
    if (to.name === 'Home' || to.name === 'Content' || to.name==='Library' || to.name==='Movement') {
        store.commit('changeType', 0)
        window.scrollTo(0, 0)
    }
    
})

